export const environment = {
  baseUrl: 'https://drzewowisni.pl',
  databaseUrl: 'postgres-db',
  databaseName: 'postgres',
  databaseUser: 'postgres',
  databasePassword: 'docker',
  databasePort: 5432,
  apiUrl: 'https://drzewowisni.pl/api',
  googleTagManagerId: 'GTM-P6PGFK4',
  production: true,
  TOKEN_SECRET:
    'zS`bt__grE_c_gcTZe;z0`7]??ug=F<z7gvj<66Mo^eDCyX?N4V=irAyBMCcHz`yy=;Ersd86zc^]yP1tVYb9osLY_6wNj354Gf<WsaC?]?lq96SREA2g3KDuI:q^Unt',
  reservationsSummaryEmailAddresses: [
    'katarzyna.wujczak@datasystempl.onmicrosoft.com',
    'tomasz.waligora@icloud.com',
    'rezerwacje@drzewowisni.pl'
  ],
  reservationEmailAddresses: ['tomasz.waligora@icloud.com', 'rezerwacje@drzewowisni.pl'],
  reservationSMSAddresses: [],
  reservationVehicleEmailAddress: ['tomasz.waligora@icloud.com'],
  overdueTasksEmail: ['tomasz.waligora@icloud.com', 'rezerwacje@drzewowisni.pl'],
  clientId: 'e279b560-17a9-4c57-844b-436e30755aa9',
  clientSecret: '68e182ac-f9d3-425e-ad4d-3612dab48f5f',
  username: 'arkadiusz.przytula@datasystem.pl',
  password: 'Demolka777$',
  url: 'https://app.gopos.io/',
  organization: '1796',
  facebookAccessToken: 'EAAIzxgb9qRMBO9KDA7GwrEZCcFR1CVfaj8eRJ72h9JHUTj9Vq94tKzy5rRZAdNZAtaw93KV9BvVz0ixOn18WWAyRYMyZAuTwxsdbIQpGZBpJPGrod6YlAWDINwmBr5NU7qJ63tCOTZAfQEIJxjx7bDUS9uiMhnfD7CVQnZCqCt51qpHLf4QqZBsfUviPDSMQUx0ZD'
};
